export default [
   {
      name: "Accenture",
      imageUrlMain:
         "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Fcompany-logos%2Faccenture.png?alt=media&token=28f4a6b9-3bc3-4871-8661-c808e4f3a126",
      imageUrlDark: "",
   },
   {
      name: "Ferrari",
      imageUrlMain:
         "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Fcompany-logos%2Fferrari.webp?alt=media&token=eb5cd68c-f71c-48c1-b6b1-d8ddecb039a6",
      imageUrlDark: "",
   },
   {
      name: "KPMG",
      imageUrlMain:
         "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Fcompany-logos%2Fkpmg.png?alt=media&token=d44f0882-4f37-40dd-9e1e-8d5faeca448b",
      imageUrlDark: "",
   },
   {
      name: "McKinsey & Company",
      imageUrlMain:
         "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Fcompany-logos%2Fmckinsey.png?alt=media&token=145a0387-197e-41dd-9d23-81eadcb9e32c",
      imageUrlDark: "",
   },
   // {
   //    name: "Sensirion AG",
   //    imageUrlMain:
   //       "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Fcompany-logos%2Fsensirion.webp?alt=media&token=8e45b591-414f-4c71-9f5e-b4d014d7ec77",
   //    imageUrlDark: "",
   // },
   {
      name: "UBS",
      imageUrlMain:
         "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Fcompany-logos%2Fubs.png?alt=media&token=3e0b640c-d365-479b-8ab4-da7f01f9ca22",
      imageUrlDark: "",
   },
   {
      name: "L'Oréal",
      imageUrlMain:
         "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Fcompany-logos%2Floreal.webp?alt=media&token=a961ecc8-8012-442c-b049-88393ce0df96",
      imageUrlDark: "",
   },
   {
      name: "ABB",
      imageUrlMain:
         "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Fcompany-logos%2Fabb.png?alt=media&token=b7f2d460-8358-4ad2-8488-78837b494851",
      imageUrlDark: "",
   },
   {
      name: "Ernst & Young",
      imageUrlMain:
         "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Fcompany-logos%2Fey.webp?alt=media&token=8ee4cc06-b2cb-4e58-85a1-d561a9ce5508",
      imageUrlDark: "",
   },
];
